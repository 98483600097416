import React from 'react'

import { Header } from '../component/Header/Header'

import { useDiaryContext } from '../../../hooks/useDiary'

import { dateToString } from '../../../library/date'

import './Detail.scss'

export const Detail = (props: { match: { params: { id: string } } }) => {
  const { loading, list } = useDiaryContext()
  const id = props.match.params.id
  const singleDiary = list.filter((diary) => diary.id === id)[0]
  if (!singleDiary || loading) return <>読み込み中</>
  return (
    <div className="detail">
      <Header title="詳細" />
      <div className="list">
        <div className="information">
          <label>日付</label>
          {dateToString(singleDiary.data.date, 'yyyy/MM/dd')}
        </div>
        <div className="information">
          <label>編集日時</label>
          {dateToString(singleDiary.data.update, 'yyyy/MM/dd HH:mm')}
        </div>
        <div className="content">
          {singleDiary.data.content.split('\n').map((each, num) => (
            <p key={num}>{each}</p>
          ))}
        </div>
      </div>
    </div>
  )
}
