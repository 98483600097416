import React from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'

import { Navigation } from './Components/Navigation'

import { useAuthentication, AuthContext } from './hooks/useAuthentication'
import { useDiary, DiaryContext } from './hooks/useDiary'
import { useAddModal, AddModalContext } from './hooks/useAddModal'

import './reset.scss'
import './App.scss'

export const App = () => {
  return (
    <HashRouter>
      <AddModalProvider>
        <DiaryProvider>
          <AuthProvider>
            <Switch>
              <Route path="/" component={Navigation} />
            </Switch>
          </AuthProvider>
        </DiaryProvider>
      </AddModalProvider>
    </HashRouter>
  )
}

const AddModalProvider = ({ children }: { children: React.ReactNode }) => {
  const addModalState = useAddModal()
  return <AddModalContext.Provider value={addModalState}>{children}</AddModalContext.Provider>
}

const DiaryProvider = ({ children }: { children: React.ReactNode }) => {
  const diaryState = useDiary()
  return <DiaryContext.Provider value={diaryState}>{children}</DiaryContext.Provider>
}

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const authState = useAuthentication()
  return <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
}
