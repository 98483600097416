import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { useAuthContext } from '../../../../hooks/useAuthentication'

import './Header.scss'

const useMenu = () => {
  const [state, setState] = useState<'close' | 'open'>('close')
  const open = () => setState('open')
  const close = () => setState('close')
  return { addClassName: ' ' + state, open, close }
}

export const Header = ({ title }: { title: string }) => {
  const { addClassName, open, close } = useMenu()
  const { requestLogout } = useAuthContext()
  return (
    <div className="header">
      <header>
        <div className="menu-button">
          <button onClick={open}></button>
        </div>
        <h2>{title}</h2>
      </header>
      <div className={'menu-background' + addClassName} onClick={close}></div>
      <div className={'menu' + addClassName}>
        <ul>
          <li>
            <Link to="/home">ホーム</Link>
          </li>
          <li>
            <Link to="/settings">設定</Link>
          </li>
          <div className="divider"></div>
          <li>
            <div className="link" onClick={requestLogout}>
              ログアウト
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}
