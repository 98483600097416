import { useState, useContext, createContext } from 'react'
import { useHistory } from 'react-router-dom'

import * as firebaseAuth from '../firebase/Authentication'

import { useFirestore } from './useFirestore'
import { useDiaryContext } from './useDiary'

import { errorMessageSelector } from '../library/errorMessage'

type UserInfoType = {
  user: firebaseAuth.User | null
  idToken: string | null
}

type FirebaseError = firebaseAuth.Error | null

type Error = {
  signupError: FirebaseError
  loginError: FirebaseError
  signupErrorMessage: string
  loginErrorMessage: string
}

type AuthType = UserInfoType &
  Error & {
    loading: boolean
  }

type UseAuthenticationType = AuthType & {
  requestSignup: (email: string, pass: string) => Promise<false | undefined>
  requestLogin: (email: string, pass: string) => Promise<false | undefined>
  requestAuth: () => Promise<void>
  requestLogout: () => Promise<void>
}

const noop = (): any => {}

export const AuthContext = createContext<UseAuthenticationType>({
  loading: false,
  user: null,
  idToken: null,
  signupError: null,
  loginError: null,
  signupErrorMessage: '',
  loginErrorMessage: '',
  requestSignup: noop,
  requestLogin: noop,
  requestAuth: noop,
  requestLogout: noop,
})

export const useAuthContext = () => {
  return useContext(AuthContext)
}

const getSignupErrorMessage = (error: FirebaseError) => {
  console.log(error)
  if (!error) return ''
  return errorMessageSelector(error)
}

const getLoginErrorMessage = (error: FirebaseError) => {
  console.log(error)
  if (!error) return ''
  return errorMessageSelector(error)
}

export const useAuthentication = (): UseAuthenticationType => {
  const history = useHistory()
  const { load } = useFirestore()
  const { loadList } = useDiaryContext()
  const [authState, setAuthState] = useState<AuthType>({
    loading: false,
    user: null,
    idToken: null,
    signupError: null,
    loginError: null,
    signupErrorMessage: '',
    loginErrorMessage: '',
  })

  const requestSignup = async (email: string, pass: string) => {
    if (email === '' || pass === '') return false
    const { response, error } = await firebaseAuth.signup(email, pass)
    if (error) {
      setAuthState({ ...authState, signupError: error, signupErrorMessage: getSignupErrorMessage(error) })
      return false
    }
    if (!response || !response.user) {
      setAuthState({ ...authState, signupErrorMessage: "不明なエラーが発生しました" })
      return false
    }
    const idToken = await firebaseAuth.getToken(response.user)
    if (idToken) {
      setAuthState({
        ...authState,
        user: response ? response.user : null,
        idToken,
      })
      loadDatabase(response.user.uid)
      history.push('/home')
    }
  }

  const requestLogin = async (email: string, pass: string) => {
    if (email === '' || pass === '') return false
    const { response, error } = await firebaseAuth.login(email, pass)
    if (error) {
      setAuthState({ ...authState, loginError: error, loginErrorMessage: getLoginErrorMessage(error) })
      return false
    }
    if (!response || !response.user) {
      setAuthState({ ...authState, loginErrorMessage: "不明なエラーが発生しました" })
      return false
    }
    const idToken = await firebaseAuth.getToken(response.user)
    if (idToken) {
      setAuthState({
        ...authState,
        user: response ? response.user : null,
        idToken,
      })
      loadDatabase(response.user.uid)
      history.push('/home')
    }
  }

  const requestAuth = async () => {
    setLoading(true)
    const user = await firebaseAuth.auth()
    const idToken = user && (await firebaseAuth.getToken(user))
    setLoading(false)
    if (!user || !idToken) {
      history.replace('/login')
    } else {
      user && loadDatabase(user.uid)
      setAuthState({ ...authState, user, idToken })
      history.replace('/home')
    }
  }

  const loadDatabase = (uid: string | undefined) => {
    if (!uid || uid === '') return
    load(uid)
    loadList(uid)
  }

  const requestLogout = async () => {
    await firebaseAuth.logout()
    history.push('/login')
  }

  const setLoading = (loading: boolean) => {
    setAuthState({ ...authState, loading })
  }

  return { ...authState, requestSignup, requestLogin, requestAuth, requestLogout }
}
