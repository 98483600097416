import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/performance'

import { firebaseConfig } from './firebaseConfig'

firebase.initializeApp(firebaseConfig)
firebase.analytics()
firebase.analytics().logEvent('load_complete')
firebase.performance()

export default firebase
