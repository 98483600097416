import { useState, useContext, createContext } from 'react'

type AddModalType = {
  addModalState: boolean
  open: () => void
  close: () => void
}

export const AddModalContext = createContext<AddModalType>({
  addModalState: false,
  open: (): any => {},
  close: (): any => {},
})

export const useAddModalContext = () => {
  return useContext(AddModalContext)
}

export const useAddModal = (): AddModalType => {
  const [state, setState] = useState<boolean>(false)
  const open = () => setState(true)
  const close = () => setState(false)
  return { addModalState: state, open, close }
}
