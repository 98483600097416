import React from 'react'

import { Header } from '../component/Header/Header'

import { useAuthContext } from '../../../hooks/useAuthentication'

import './Settings.scss'

export const Settings = () => {
  const { loading, user, requestLogout } = useAuthContext()
  if (!user && loading) return <>読み込み中</>
  return (
    <div className="settings">
      <Header title="Settings" />
      <div className="logout">
        <button onClick={requestLogout}>ログアウト</button>
      </div>
      <div className="userinfo">
        <div>
          <span>displayName</span>
          <span>{user ? user.displayName : 'null'}</span>
        </div>
        <div>
          <span>email</span>
          <span>{user ? user.email : 'null'}</span>
        </div>
        <div>
          <span>emailVerified</span>
          <span>{user ? user.emailVerified : 'null'}</span>
        </div>
        <div>
          <span>phoneNumber</span>
          <span>{user ? user.phoneNumber : 'null'}</span>
        </div>
        <div>
          <span>photoURL</span>
          <span>{user ? user.photoURL : 'null'}</span>
        </div>
        <div>
          <span>providerId</span>
          <span>{user ? user.providerId : 'null'}</span>
        </div>
        <div>
          <span>uid</span>
          <span>{user ? user.uid : 'null'}</span>
        </div>
      </div>
    </div>
  )
}
