import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { Home } from './Home/Home'
import { Detail } from './Detail/Detail'
import { Settings } from './Settings/Settings'
import { Add } from './Add/Add'

import { useEffectOnce } from '../../hooks/useEffectOnce'
import { useAuthContext } from '../../hooks/useAuthentication'

export const Auth = () => {
  const { requestAuth } = useAuthContext()
  useEffectOnce(() => {
    requestAuth()
  })
  return (
    <>
      <Switch>
        <Route path="/home" component={Home} />
        <Route path="/detail/:id" component={Detail} />
        <Route path="/settings" component={Settings} />
      </Switch>
      <Add />
    </>
  )
}
