import React from 'react'

import { useAuthContext } from '../../../hooks/useAuthentication'
import { useBaseInputContext } from '../../../hooks/useBaseInput'

import './Login.scss'

export const Login = () => {
  const { requestLogin, loginErrorMessage } = useAuthContext()
  const { mail, setMail, pass, setPass } = useBaseInputContext()
  const request = () => requestLogin(mail, pass)
  const keyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') request()
  }
  return (
    <div className="login">
      <div className="input">
        <label>メールアドレス</label>
        <input type="text" onChange={(e) => setMail(e.target.value)} value={mail} onKeyPress={(e) => keyPress(e)} />
      </div>
      <div className="input">
        <label>パスワード</label>
        <input type="password" onChange={(e) => setPass(e.target.value)} value={pass} onKeyPress={(e) => keyPress(e)} />
      </div>
      {loginErrorMessage && <div className="error">{loginErrorMessage}</div>}
      <button onClick={request}>ログイン</button>
    </div>
  )
}
