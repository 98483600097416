import { useState, useContext, createContext } from 'react'

import * as firestore from '../firebase/Firestore'

import { useAddModalContext } from './useAddModal'

type SingleDiaryType = {
  id: string
  data: {
    date: firestore.FirebaseTime
    update: firestore.FirebaseTime
    content: string
  }
}

type InputType = {
  date: string
  content: string
}

type ListType = {
  list: SingleDiaryType[]
}

type DiaryType = ListType &
  InputType & {
    loading: boolean
  }

type UseDiaryType = DiaryType & {
  updateInput: (target: string, value: string) => void
  loadList: (uid: string) => Promise<void>
  addList: (uid: string) => Promise<void>
}

const noop = (): any => {}

export const DiaryContext = createContext<UseDiaryType>({
  loading: false,
  date: '',
  content: '',
  updateInput: noop,
  list: [],
  loadList: noop,
  addList: noop,
})

export const useDiaryContext = () => {
  return useContext(DiaryContext)
}

export const useDiary = (): UseDiaryType => {
  const { close } = useAddModalContext()
  const getPresentDay = () => {
    const time = new Date()
    return time.getFullYear() + '-' + ('00' + (time.getMonth() + 1)).slice(-2) + '-' + ('00' + time.getDate()).slice(-2)
  }

  const [loading, setLoading] = useState<boolean>(false)
  const [list, setList] = useState<SingleDiaryType[]>([])
  const [input, setInput] = useState<InputType>({
    date: getPresentDay(),
    content: '',
  })

  const updateInput = (target: string, value: string) => {
    setInput({ ...input, [target]: value })
  }

  const loadList = async (uid: string) => {
    setLoading(true)
    const collection = await firestore.loadCollection('/diary/v1/' + uid)
    setList(collection.docs)
    setLoading(false)
  }

  const addList = async (uid: string) => {
    if (input.date === '' || input.content === '') return
    setLoading(true)
    const data = { date: firestore.dateConvert(input.date), content: input.content }
    await firestore.addCollection('/diary/v1/' + uid, data)
    setLoading(false)
    loadList(uid)
    close()
  }

  return { loading, ...input, updateInput, list, loadList, addList }
}
