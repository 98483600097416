import firebase from './firebase'

export const firestore = firebase.firestore()

export type FirebaseTime = firebase.firestore.Timestamp

export const loadCollection = async (path: string) => {
  const collection: any = { docs: [] }
  await firestore
    .collection(path)
    .orderBy('date', 'desc')
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        console.log(`${doc.id} => ${doc.data()}`)
      })
      const docs = querySnapshot.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        }
      })
      return (collection.docs = docs)
    })
  return collection
}

export const addCollection = (path: string, collection: object) => {
  const newCollection = {
    ...collection,
    update: firebase.firestore.Timestamp.fromDate(new Date()),
  }
  firestore
    .collection(path)
    .add(newCollection)
    .then((docRef) => {
      console.log('Document written with ID: ', docRef.id, { docRef })
    })
    .catch((error) => {
      console.log('Error adding document: ', error)
    })
}

export const dateConvert = (date: string): firebase.firestore.Timestamp => {
  return firebase.firestore.Timestamp.fromDate(new Date(date))
}

export const convertToDate = (date: firebase.firestore.Timestamp): Date => {
  return date.toDate()
}
