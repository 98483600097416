import firebase from './firebase'

export type User = firebase.User
export type Error = firebase.FirebaseError

export const getToken = async (user: firebase.User) => {
  if (!user) return false
  return await user.getIdToken()
}

export const auth = () => {
  return new Promise<firebase.User | null>((resolve) => {
    firebase.auth().onAuthStateChanged((user) => {
      resolve(user || null)
    })
  })
}

type SignupResultType = {
  response: firebase.auth.UserCredential | null
  error: Error | null
}

export const signup = async (email: string, password: string) => {
  const signupResult: SignupResultType = { response: null, error: null }
  await firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((response) => {
      return (signupResult.response = response)
    })
    .catch((error) => {
      return (signupResult.error = error)
    })
  return signupResult
}

type LoginResultType = SignupResultType

export const login = async (email: string, password: string) => {
  const loginResult: LoginResultType = { response: null, error: null }
  await firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((response) => {
      return (loginResult.response = response)
    })
    .catch((error) => {
      return (loginResult.error = error)
    })
  return loginResult
}

export const logout = async () => {
  return await firebase.auth().signOut()
}
