import React from 'react'
import { Link } from 'react-router-dom'

import { Header } from '../component/Header/Header'

import { useAuthContext } from '../../../hooks/useAuthentication'
import { useDiaryContext } from '../../../hooks/useDiary'
import { useAddModalContext } from '../../../hooks/useAddModal'

import { dateToString } from '../../../library/date'

import './Home.scss'

export const Home = () => {
  const { loading, user } = useAuthContext()
  const { list } = useDiaryContext()
  const { open: openAddModal } = useAddModalContext()
  if (!user && loading) return <>読み込み中</>
  return (
    <div className="home">
      <Header title="ホーム" />
      <div className="add">
        <h2>日記一覧</h2>
        <button onClick={openAddModal}>追加</button>
      </div>
      <ul className="diary">
        {list &&
          list.map((diary, number) => {
            return (
              <li key={number}>
                <Link to={'/detail/' + diary.id}>
                  <span>{dateToString(diary.data.date, 'yyyy/MM/dd')}</span>
                  {diary.data.content.split('\n').map((each, num) => (
                    <p key={num}>{each}</p>
                  ))}
                </Link>
              </li>
            )
          })}
      </ul>
    </div>
  )
}
