import React from 'react'

import { useAddModalContext } from '../../../hooks/useAddModal'
import { useAuthContext } from '../../../hooks/useAuthentication'
import { useDiaryContext } from '../../../hooks/useDiary'

import './Add.scss'

export const Add = () => {
  const { addModalState, close } = useAddModalContext()
  const { loading, user } = useAuthContext()
  const { date, content, updateInput, addList } = useDiaryContext()
  if (!user && loading) return null
  const modalClass = addModalState ? ' open' : ' close'
  return (
    <div className="add">
      <div className="contents">
        <div className={'modal-contents' + modalClass}>
          <header>
            <button className="cancel" onClick={close}>
              キャンセル
            </button>
            <h2>新規作成</h2>
            <button className="add" onClick={() => user && addList(user.uid)}>
              送信
            </button>
          </header>
          <div className="contents">
            <div className="contents-inner-modal">
              <div>
                <input type="date" value={date} onChange={(e) => updateInput('date', e.target.value)} />
              </div>
              <div>
                <textarea defaultValue={content} onChange={(e) => updateInput('content', e.target.value)}></textarea>
              </div>
              <div className="send">
                <button onClick={() => user && addList(user.uid)}>追加</button>
              </div>
            </div>
          </div>
        </div>
        <div className={'modal-background' + modalClass}></div>
      </div>
    </div>
  )
}
