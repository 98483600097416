import React from 'react'
import { Route, Switch, Link, useLocation } from 'react-router-dom'

import { Login } from './Login/Login'
import { Signup } from './Signup/Signup'

import { useBaseInput, BaseInputContext } from '../../hooks/useBaseInput'

import './Base.scss'

export const Base = () => {
  const location = useLocation()
  const baseInput = useBaseInput()
  return (
    <div className="base">
      <BaseInputContext.Provider value={baseInput}>
        <div className="wrapper">
          <div className="box">
            <div className="tab">
              <Link to="/signup" className={location.pathname === '/signup' ? 'active' : ''}>
                新規登録
              </Link>
              <Link to="/login" className={location.pathname === '/login' ? 'active' : ''}>
                ログイン
              </Link>
            </div>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/signup" component={Signup} />
            </Switch>
          </div>
        </div>
      </BaseInputContext.Provider>
    </div>
  )
}
