import { useState } from 'react'
import * as firebaseFirestore from '../firebase/Firestore'

export const useFirestore = () => {
  const [collection, setCollection] = useState<any>({ collection: { docs: [] } })
  const [input, setInput] = useState<string>('')

  const load = async (uid: string) => {
    const collection = await firebaseFirestore.loadCollection('/user/v1/' + uid)
    setCollection(collection)
  }

  const add = async (uid: string) => {
    if (input === '') return
    const data = { content: input }
    await firebaseFirestore.addCollection('user/v1/' + uid, data)
    setInput('')
    load(uid)
  }

  return { collection, input, setInput, load, add }
}
