export const firebaseConfig = {
  apiKey: 'AIzaSyDkA8DSRrIf1mk0OqUAbV0q1E6kWbwmyf4',
  authDomain: 'single-diary.firebaseapp.com',
  databaseURL: 'https://single-diary.firebaseio.com',
  projectId: 'single-diary',
  storageBucket: 'single-diary.appspot.com',
  messagingSenderId: '188366586402',
  appId: '1:188366586402:web:447beae19e3daaede1c07b',
  measurementId: 'G-6VMMBFC9L4',
}
