import { useState, useContext, createContext } from 'react'

type BaseInputType = {
  mail: string
  setMail: (string: string) => void
  pass: string
  setPass: (string: string) => void
}

export const BaseInputContext = createContext({
  mail: '',
  setMail: (string: string): any => {},
  pass: '',
  setPass: (string: string): any => {},
})

export const useBaseInputContext = () => {
  return useContext(BaseInputContext)
}

export const useBaseInput = (): BaseInputType => {
  const [mail, setMail] = useState<string>('')
  const [pass, setPass] = useState<string>('')
  return { mail, setMail, pass, setPass }
}
