import * as firebaseAuth from '../firebase/Authentication'

export const errorMessageSelector = (error: firebaseAuth.Error) => {
  if (!error) return ''
  switch (error.code) {
    // https://firebase.google.com/docs/auth/admin/errors?hl=ja
    case 'auth/claims-too-large':
      return 'ペイロードが大きすぎます'
    case 'auth/email-already-exists':
      return 'このアドレスは使用できません'
    case 'auth/id-token-expired':
      return 'トークンが期限切れです'
    case 'auth/id-token-revoked':
      return 'トークンが取り消されました'
    case 'auth/insufficient-permission':
      return '権限がありません'
    case 'auth/internal-error':
      return 'サーバーエラーです'
    case 'auth/invalid-argument':
      return '引数が無効です'
    case 'auth/invalid-claims':
      return '無効です'
    case 'auth/invalid-continue-uri':
      return '続行URLが無効です'
    case 'auth/invalid-creation-time':
      return '作成時刻が無効です'
    case 'auth/invalid-credential':
      return 'エラーです'
    case 'auth/invalid-disabled-field':
      return 'エラーです'
    case 'auth/invalid-display-name':
      return 'エラーです'
    case 'auth/invalid-dynamic-link-domain':
      return 'エラーです'
    case 'auth/invalid-email':
      return 'メールアドレスの形式が間違っています'
    case 'auth/invalid-email-verified':
      return 'エラーです'
    case 'auth/invalid-hash-algorithm':
      return 'エラーです'
    case 'auth/invalid-hash-block-size':
      return 'エラーです'
    case 'auth/invalid-hash-derived-key-length':
      return 'エラーです'
    case 'auth/invalid-hash-key':
      return 'エラーです'
    case 'auth/invalid-hash-memory-cost':
      return 'エラーです'
    case 'auth/invalid-hash-parallelization':
      return 'エラーです'
    case 'auth/invalid-hash-rounds':
      return 'エラーです'
    case 'auth/invalid-hash-salt-separator':
      return 'エラーです'
    case 'auth/invalid-id-token':
      return 'エラーです'
    case 'auth/invalid-last-sign-in-time':
      return 'エラーです'
    case 'auth/invalid-page-token':
      return 'エラーです'
    case 'auth/invalid-password':
      return 'エラーです'
    case 'auth/invalid-password-hash':
      return 'エラーです'
    case 'auth/invalid-password-salt':
      return 'エラーです'
    case 'auth/invalid-phone-number':
      return 'エラーです'
    case 'auth/invalid-photo-url':
      return 'エラーです'
    case 'auth/invalid-provider-data':
      return 'エラーです'
    case 'auth/invalid-provider-id':
      return 'エラーです'
    case 'auth/invalid-session-cookie-duration':
      return 'エラーです'
    case 'auth/invalid-uid':
      return 'エラーです'
    case 'auth/invalid-user-import':
      return 'エラーです'
    case 'auth/maximum-user-count-exceeded':
      return 'エラーです'
    case 'auth/missing-android-pkg-name':
      return 'エラーです'
    case 'auth/missing-continue-uri':
      return 'エラーです'
    case 'auth/missing-hash-algorithm':
      return 'エラーです'
    case 'auth/missing-ios-bundle-id':
      return 'エラーです'
    case 'auth/missing-uid':
      return 'エラーです'
    case 'auth/operation-not-allowed':
      return 'エラーです'
    case 'auth/phone-number-already-exists':
      return 'エラーです'
    case 'auth/project-not-found':
      return 'エラーです'
    case 'auth/reserved-claims':
      return 'エラーです'
    case 'auth/session-cookie-expired':
      return 'エラーです'
    case 'auth/session-cookie-revoked':
      return 'エラーです'
    case 'auth/uid-already-exists':
      return 'エラーです'
    case 'auth/unauthorized-continue-uri':
      return 'エラーです'
    case 'auth/user-not-found':
      return 'ユーザーが見つかりません'
    // 以下追加
    case 'auth/weak-password':
      return 'パスワードが弱すぎます'
    case 'auth/email-already-in-use':
      return 'このメールアドレスは利用できません'
    default:
      return '不明なエラーです'
  }
}
