import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { Base } from './Base/Base'
import { Auth } from './Auth/Auth'

export const Navigation = () => {
  return (
    <Switch>
      <Route path="/login" component={Base} />
      <Route path="/signup" component={Base} />
      <Route path="/" component={Auth} />
    </Switch>
  )
}
